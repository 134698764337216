<template>
  <section class="bg-white py-2 mt-2">
    <div class="lg:boxed-tabs  lg:nav lg:nav-tabs lg:flex items-center justify-between rounded-md bg-white w-full mt-4" role="group">
      <div class="w-fit">
        <router-link v-for="(item, index) in items"  :key="index"
                :id="item.id"
                :to="$can(item.permission) ? item.to : ''"
                :class="item.class"
                class="px-8 py-2 border-0 self-end"
        >
          {{ $can(item.permission) ? item.label : '' }}
        </router-link>
      </div>
      <div v-if="items[0].class === 'on'" class="lg:flex w-full lg:w-max mt-4 lg:mt-0">
        <Button v-if="$can('ajustes.usuarios.crear')" style="background-color: #1C3FAA" class="mr-4 mb-2 w-full" label="Nuevo usuario" @click="rutaNuevoUsuario" icon="pi pi-user-plus" />
        <Dropdown  class="mr-4 mb-2 w-full" @change="filtrarTabla" v-model="filterNameSelected" :options="listadoUsuarios" optionLabel="name" :filter="true" placeholder="Buscar por nombre" :showClear="true"></Dropdown>
      </div>
      <div v-else class="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-4 lg:mt-0">
        <Button v-if="$can('ajustes.grupos.access')" @click="$router.push({name: 'ajustes.grupos.autorizaciones'})" class="p-button-secondary p-button-outlined mr-4 mb-2 w-full" label="Autorizaciones" icon="pi pi-lock-open" />
        <Button v-if="$can('ajustes.grupos.access')" @click="openModal" style="background-color: #1C3FAA" class="mr-4 mb-2 w-full" label="Nuevo grupo" icon="pi pi-user-plus" />
        <Dropdown
          class="mr-4 mb-2 w-full"
          @change="filtrarGrupo"
          v-model="filterGroupSelected"
          :options="listadoGrupos"
          optionLabel="name"
          optionValue="name"
          :filter="true"
          placeholder="Buscar"
          :showClear="true" />
        <Dialog :showHeader="false" :closable="false" class="shadow-md rounded-lg" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
          <div class="w-full">
            <div class="flex justify-center w-full">
              <div>
                <div class="flex justify-center">
                  <img class="m-4" src="../../../../../assets/images/icons/modal-createpub-icon.png" alt="">
                </div>
                <p class="font-medium text-2xl" style="color: #7F7E81">
                  Nuevo Grupo
                </p>
              </div>
            </div>
            <div class="my-4">
              <label for="nameGroup">Nombre</label>
              <InputText v-model="grupoNombre" id="nameGroup" class="w-full mt-2" type="text" />
            </div>
            <div class="my-4">
              <label for="nameGroup">Ruta principal</label>
              <div class="w-full mt-2">
                <Dropdown class="w-full rounded-md border-gray-300" v-model="grupoRutaPpal" :options="listadoMenusPpales" optionLabel="action" optionValue="action" :filter="true" placeholder="Seleccione" :showClear="true">
                </Dropdown>
              </div>
            </div>
          </div>
          <div class="w-full grid grid-cols-2 gap-2">
            <Button label="Cancelar" style="background-color: #F8F8F8;" @click="closeModal" class="p-button-text rounded-lg"/>
            <Button label="Guardar" style="background-color: #1C3FAA"  @click="crearGrupo" autofocus class="rounded-lg" />
          </div>
        </Dialog>
      </div>
    </div>
    <router-view />
    <div class="md:flex md:justify-between mt-4 principalButtonMenu">
    </div>
  </section>
</template>
<script>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import Swal from 'sweetalert2'
  import GruposService from '../../services/grupos.service'
  import usuariosStore from '../../stores/usuarios.store'
  import MenusService from '../../services/menus.service'
  import { can } from '../../../../../utils/permission'
  export default {
  name: 'menuUsuarios',
  setup () {
    // Services
    const _GruposService = ref(new GruposService())
    const _MenusService = ref(new MenusService())
    const route = useRoute()
    const router = useRouter()
    // References
    const displayModal = ref(false)
    const filterNameSelected = ref('')
    const filterGroupSelected = ref('')
    const grupoNombre = ref('')
    const grupoRutaPpal = ref('')
    const listadoGrupos = ref([])
    const listadoMenusPpales = ref([])
    const items = computed(() => {
      return [
          {
            id: 'usuarios',
            to: { name: 'ajustes.usuarios', params: { type: 'usuarios' } },
            permission: 'ajustes.usuarios',
            class: getActive([
                'ajustes.usuarios'
            ]),
            label: 'Usuarios'
          },
          {
            id: 'grupos',
            to: { name: 'ajustes.grupos', params: { type: 'grupos' } },
            permission: 'ajustes.grupos',
            class: getActive([
                'ajustes.grupos'
            ]),
            label: 'Grupos'
          }
      ]
    })
    // Methods
    const getActive = (routeNames = []) => {
        if (routeNames.includes(route.name)) {
        return 'on'
        }
        return 'off'
    }
    const getMenus = () => {
      _MenusService.value.get().then(({ data }) => {
        const array = []
        for (const i of data) {
          if (i.nivel !== 1) {
            // i.name = data.filter(a => a.id === i.parent_menu_id)[0].name + ' - ' + i.name
            array.push(i)
          }
        }
        listadoMenusPpales.value = array
        usuariosStore.commit('setListadoMenusPpales', array)
      })
    }
    const rutaNuevoUsuario = () => {
      router.push({ name: 'ajustes.usuarios.crear' })
      usuariosStore.commit('limpiarUsuarioData')
    }
    const listadoUsuarios = computed(() => usuariosStore.getters._usuarios)
    const listarGrupos = () => {
      _GruposService.value.getall().then(({ data }) => {
        usuariosStore.commit('setTodosGrupos', data)
        listadoGrupos.value = data
      })
    }
    const openModal = () => {
        displayModal.value = true
    }
    const crearGrupo = () => {
      if (listadoGrupos.value.some(a => (a.name.toUpperCase()).trim() === (grupoNombre.value.toUpperCase()).trim())) {
        Swal.fire(
          'Error',
          'Este nombre de grupo ya se encuentra registrado',
          'warning'
        )
      } else {
        if (!grupoNombre.value || !grupoRutaPpal.value) {
          Swal.fire(
            'Error',
            'Debe ingresar todos los campos',
            'warning'
          )
        } else {
          const object = {
            name: grupoNombre.value,
            routeName: grupoRutaPpal.value,
            state: true
          }
          _GruposService.value.post(object).then(({ data }) => {
            if (data.error) {
              Swal.fire(
                'Error',
                'Error al crear grupo, intente nuevamente',
                'error'
              ).then(() => {
                closeModal()
              })
            } else {
              closeModal()
              Swal.fire(
                'Exito',
                'Grupo creado satisfactoriamente',
                'success'
              )
            }
          })
        }
      }
    }
    const closeModal = () => {
        displayModal.value = false
        grupoNombre.value = ''
        grupoRutaPpal.value = ''
    }
    const filtrarTabla = () => {
      usuariosStore.dispatch('filtroPorNombreTabla', filterNameSelected.value ? filterNameSelected.value : '')
    }
    const filtrarGrupo = () => {
      usuariosStore.dispatch('filtroPorGrupo', filterGroupSelected.value ? filterGroupSelected.value : '')
    }
    onMounted(() => {
      if (!can('ajustes.usuarios')) {
        router.push({ name: 'ajustes.grupos' })
      }
      usuariosStore.dispatch('getUsuarios')
      listarGrupos()
      getMenus()
    })

    return {
      openModal,
      closeModal,
      displayModal,
      listadoUsuarios,
      filterNameSelected,
      filtrarTabla,
      crearGrupo,
      grupoNombre,
      listadoGrupos,
      filterGroupSelected,
      filtrarGrupo,
      items,
      grupoRutaPpal,
      listadoMenusPpales,
      rutaNuevoUsuario
    }
  }
}
</script>

<style scoped>
  .on {
    border-bottom: 3px solid #1C3FAA;
    color: #1C3FAA;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
  .off {
    text-align: center;
  }
  :global(.swal2-container) {z-index: 1000000 !important}

</style>
